$(document).ready(function() {


    $('.partners').slick({

      autoplay: false,
      infinite: true,
      slidesToShow: 4,
    slidesToScroll: 4,
      draggable: true,
      arrows: true,
      dots: false,
      responsive: [
        {
     breakpoint: 520,
     settings: {
       autoplay: true,
       infinite: true,
       slidesToShow: 2,
     slidesToScroll: 2,
     arrows: false

     }
   }
 ]
    });



$('#section0 .is-animated.fidf').addClass('animated fadeInDown').css('animation-delay', '0.0s');
$('#section0 .is-animated.fid').addClass('animated fadeInDown').css('animation-delay', '0.48s');
$('#section0 .is-animated.fiu').addClass('animated fadeInUp').css('animation-delay', '0.48s');
$('#section0 .is-animated.fiub').addClass('animated fadeInUp').css('animation-delay', '0.99s');

$('.mobilemenu').on('click', function() {
  $('.hamburger-menu').toggleClass('animate');
  $('.mobileoverlay').toggleClass('open');
})

$('.mobileoverlay li a').click(function() {

      $('.mobilemenu').trigger('click');
        if ($(this).hasClass('menu-link-class')){
            $(this).closest('.nav-bar').removeClass('nav-open');
        }
    });


  $('#fullpage').fullpage({
    anchors: ['start', 'service', 'ueberuns', 'mieten', 'kontakt'],
    navigation: true,
    navigationPosition: 'left',
    navigationTooltips: ['Start', 'Service', 'Über uns', 'Mieten', 'Kontakt'],
    responsiveHeight: 639,
    responsiveWidth: 1264,
    verticalCentered:false,
    afterLoad: function(anchorLink, index) {
      var section = 'section';
      var myIndex = section + (index-1);
         $('#'+myIndex+' .background-zoom').addClass('gostart');
    },
     onLeave: function(index, nextIndex, direction) {

         var section = 'section';
         var myIndex = section + index;

         if(index == 1){
           $('.callus .is-animated').removeClass('animated fadeOutDown');
           $('.callus .is-animated').css('animation-delay', '0.99s');
           $('.callus .is-animated').addClass('animated bounceInRight');
         }
         if (index >= 2 && direction == 'up'){
           $('.callus .is-animated').removeClass('animated bounceInRight');
           $('.callus .is-animated').css('animation-delay', '0.00s');
           $('.callus .is-animated').addClass('animated fadeOutDown');
         }

   	       if ($(window).width() > 1265){


           //fadeInUp
           $('#'+myIndex+' .is-animated.fiu').addClass('animated fadeInUp').css('animation-delay', '0.45s');

           //fadeInUplater
           $('#'+myIndex+' .is-animated.fiul').addClass('animated fadeInUp').css('animation-delay', '0.9s');

           //fadeInUpBig
           $('#'+myIndex+' .is-animated.fiub').addClass('animated fadeInUpBig').css('animation-delay', '0.3s');

           //fadeInDown
           $('#'+myIndex+' .is-animated.fid').addClass('animated fadeInDown').css('animation-delay', '0.2s');

           //fadeInLeft
           $('#'+myIndex+' .is-animated.fil').addClass('animated fadeInLeft').css('animation-delay', '0.20s')


           //fadeInRight
           $('#'+myIndex+' .is-animated.fir').addClass('animated fadeInRight').css('animation-delay', '0.20s')

           //fadeInRightBig
           $('#'+myIndex+' .is-animated.fir').addClass('animated fadeInRightBig').css('animation-delay', '0.0s')


         }

       }
  });




});
